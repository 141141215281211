/* Inria serif */
@import url("https://fonts.googleapis.com/css2?family=Inria+Serif:wght@700&display=swap");
/* Poppins */
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800;900&display=swap");
/* Paprika */
@import url("https://fonts.googleapis.com/css2?family=Paprika&display=swap");
@import url("https://fonts.googleapis.com/css?family=Roboto");

@import "~bootstrap/dist/css/bootstrap.css";
@import "~@formio/js/dist/formio.full.min.css";

@tailwind base;
@tailwind components;
@tailwind utilities;
/*Ẩn các component formio*/
.formio-component-customDefaultValuePanel{
  display: none;
}
.formio-component-calculateValuePanel{
  display: none;
}

.formio-component-panel-disable-function{
  display: none;
}
.formio-component-datePicker\.disable {
  display: none;
}
.w-400{
  width: 400px;
}
.w-30pt{
  width: 30%;
}
.w-70pt{
  width: 70%;
}
.div-form-view-detail-full{
  margin: auto;
  padding: 20px;
  margin: 0px auto;
  border-radius: 5px 5px 5px 5px;
  box-shadow: 0px 0px 3px 1px #e6e6e6;
  display: flex;
  background: white;
  flex-direction: column;
  justify-content: space-between;
}
/*Ẩn các component formio*/
.div-form-view-detail{
  margin: auto;
  padding: 20px;
  margin: 0px auto;
  border-radius: 5px 5px 5px 5px;
  box-shadow: 0px 0px 3px 1px #e6e6e6;
  display: flex;
  background: white;
  min-height: calc(100vh - 84px);
  flex-direction: column;
  justify-content: space-between;
}
.div-form-view{
  background: url("https://mir-s3-cdn-cf.behance.net/project_modules/max_1200/ef9f0d157382995.63bccc9f5022a.jpg") center center / cover no-repeat rgb(255, 255, 255);
  min-height: calc(100vh);
}
.Class-menu-default {
  padding: 20px 20px;
    background: linear-gradient(149deg, #eaf1ff 0%, #f3d0dc 100%);
    border-radius: 10px;
}

.formcomponent{
  background: linear-gradient(90deg, #6782df, #29b8e9);
  border: white;
}
.input-group-append .input-group-text{
  padding: 10px;
  border-radius: 0px 6px 6px 0px;
}
.form-builder-panel{
  border: 1px solid #a5f6ff;
    margin-bottom: 3px;
}
.form-builder-panel .card-header{
  background: #e8f8ff;
  border: 0px;
}
h4 {
  line-height: 1.325 !important;
}
.btn-create-bieumau{
  background: linear-gradient(90deg, #6782df, #29b8e9) !important;
    color: white;
    border: 1px solid #c4c9ff;
    border-radius: 5px 5px 20px 5px;
}
.formcomponent {
  width: 100%;
}
.App {
  padding: 30px;
}
.bg-primaryLinear{
  background-image: linear-gradient(180deg, #0069ff 0.48%, #D5E3F1 100%);
}
.big-title {
  text-align: center;
  font-weight: 500;
  font-size: 2.5em;
}
.input-key-form{
  width: 300px;
    margin: 4px;
}
.input-key-form .ant-input {
  height: 40px;
}

#copyrights{
  position: absolute;
  bottom: 2%;
  right: 2%;
}

#copyrights a{
  text-decoration: none;
  color: var(--bg-darker);
}

/* tpn card style */
.tpn_card{
  background: white;
  /* border-radius:27px; */
  padding: 10px 10px 20px 10px;
  /* margin-left: 15px; */
  box-shadow: 0px 0px 10px 4px #eeeeee;
  border-radius: 10px;
  width: 100%;
 
}

.tpn_card:hover{
  box-shadow: 0px 0px 5px 2px #aed9ed6e;
}
.ant-picker-dropdown-placement-topLeft{
  z-index: 3000 !important;
}
.moTa-p{
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  display: -webkit-box;

  overflow: hidden;
  text-align: justify;
  min-height: 68.2px;
}
.tieude-p{
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  display: -webkit-box;
  margin-bottom: 10px;
  margin-top: 5px;
  overflow: hidden;
  text-align: justify;
  min-height: 38.4px;
}
.tpn_card img{
  border-radius:10px;
  box-shadow: 1px 7px 13px var(--shadow);
  object-fit: cover;
  height: 120px;
  width: 100%;
}

.tpn_card h5{
  color: var(--bg-dark);
}

.tpn_card p{
  /* color: var(--font-body); */
  font-weight:400;
  margin-bottom:0px;
}

.tpn_card .tpn_btn{
  border: 1px solid #d9d9d9;
  line-height: normal;
  background: var(--bg-dark);
  border-radius:17px;
  text-decoration:none;
  color: #3c00ff;
  box-shadow: 1px 7px 13px var(--shadow);
  transition: all .7s ease;
}
.btn-gra-blue{
  background:  linear-gradient(to right, #6a11cb 0%, #2575fc 100%);
}
.tpn_card .tpn_btn:hover{
  background:  linear-gradient(to right, #6a11cb 0%, #2575fc 100%);
  color: white;
  box-shadow: none;
}

/* Styling for the main box */
.boxx {
    position: relative;
    width: 400px;
    height: 300px;
}

/* Styling for the gradient animation on the main box */
.boxx::before {
    content: "";
    position: absolute;
    inset: 0;
    background: repeating-conic-gradient(from var(--a),
            #0f0,
            #ff0,
            #0ff,
            #f0f,
            #0ff);
    border-radius: 20px;
    animation: rotating 4s linear infinite;
    /* Applying rotation animation */
}

/* Styling for the blurred gradient on the main box */
.boxx::after {
    content: "";
    position: absolute;
    inset: 0;
    background: repeating-conic-gradient(from var(--a),
            #0f0,
            #ff0,
            #0ff,
            #f0f,
            #0ff);
    border-radius: 20px;
    animation: rotating 4s linear infinite;
    /* Applying rotation animation */
    filter: blur(40px);
    opacity: 0.75;
}

/* Styling for the inner span within the main box */
.boxx span {
    position: absolute;
    inset: 4px;
    background: #222;
    /* Setting background color */
    border-radius: 16px;
    z-index: 1;
    /* opacity: 0; */
    /* Uncomment to hide the inner span */
}

/* Custom property for the rotation angle */
@property --a {
    syntax: "<angle>";
    inherits: false;
    initial-value: 0deg;
}

/* Keyframes for the rotating animation */
@keyframes rotating {
    0% {
        --a: 0deg;
    }

    100% {
        --a: 360deg;
    }
}

/* Styling for the text inside the nested box */
.boxx .box1x span {
    color: white;
    display: flex;
    align-items: center;
    padding: 34px;
  
}
.div-key-form{
  margin-bottom: 10px;
}
.div-key-form:before {
  animation: glower 35s linear infinite;
  animation-duration: 35s;
  animation-timing-function: linear;
  animation-delay: 0s;
  animation-iteration-count: infinite;
  animation-direction: normal;
  animation-fill-mode: none;
  animation-play-state: running;
  animation-name: glower;
  animation-timeline: auto;
  animation-range-start: normal;
  animation-range-end: normal;
  background: linear-gradient(45deg, #4fcf70, #fad648, #a767e5, #12bcfe, #44ce7b);
  background-size: 400%;
  border-radius: 10px;
  content: "";
  height: 48px;
  /* left: -4px; */
  position: absolute;
  /* top: -5px; */
  width: 308px;
  z-index: -1;
}
@keyframes glower {
  0% {
      background-position: 0 0
  }

  50% {
      background-position: 400% 0
  }

  to {
      background-position: 0 0
  }
}
@layer base {
  html {
    scroll-behavior: smooth;
  }
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  p,
  span {
    @apply text-dark_primary;
  }
  h1 {
    @apply font-Poppins lg:text-6xl text-3xl font-extrabold;
  }
  h2 {
    @apply font-Inria text-5xl font-bold;
  }
  h3 {
    @apply font-Paprika text-5xl;
  }
  h4 {
    @apply md:text-4xl text-3xl !leading-relaxed font-Paprika;
  }
  h6 {
    @apply font-Paprika font-bold text-[#253D57] leading-8;
  }

  p {
    @apply font-Poppins text-[0.95rem];
  }
  button {
    @apply font-Poppins;
  }
  input,
  textarea {
    @apply bg-transparent outline-none font-Poppins;
  }
}

@layer utilities {
 
  .title {
    @apply md:text-3xl text-2xl;
  }
  .subtitle {
    color: #454e57;
  }
}
.swiper-pagination-bullet {
  @apply w-3 h-3 bg-dark_primary;
}


.has-feedback .form-control{
  min-width: 150px !important;
}
.checkbox{
  min-width: 100px !important;
}
.red{
  color: red !important;
}
.red *{
  color: red !important;
}
code {
  
}
.yellow{
  color: #b78103;
}
.yellow-l{
  color: #f0d908;
}
.green {
  color: #10f01b
}
.table-cus-antd .ant-table-body{
  max-height: calc(100vh - 220px) !important;
}
.table-cus-antd{
  min-height: unset !important;
  margin-bottom: 8px;
  /* max-height: calc(100vh - 180px) !important; */
}

.img-animazom{
  animation-name: zoom-cycle;
  animation-duration: 8s;
  animation-iteration-count: infinite;
  animation-delay: 3s; /* Độ trễ 3 giây trước khi bắt đầu animation */
  animation-fill-mode: forwards; /* Giữ giá trị cuối của animation để không ảnh hưởng đến hiệu ứng khác */
}
@keyframes zoom-cycle {
  0%, 100% {
    transform: scale(1); /* Trở lại kích thước ban đầu */
  }
  50% {
    transform: scale(1.07); /* Phóng to thêm 10% */
  }
}/*  */
.pd-item-0 .MuiGrid-item{
  padding: 0px !important;
}
.help{
  color: #707070;
  font-size: 15px !important;
}
.ant-tooltip{
  z-index: 13101 !important;
}
.ant-modal{
  top: 50px !important;
}

.row{
  background: #9f9f9f0d;
  border: 1px solid #dedede;
  padding: 10px 0px;
  margin: 1px;
  border-radius: 5px;
}
.ant-table-not-height .ant-empty-normal{
  height: 60px !important;
}
.ant-popover{
  z-index: 6000!important;
}
.choices__list .choices__item{
  -webkit-line-clamp: 2!important;
  -webkit-box-orient: vertical !important;
  display: -webkit-box!important;
  overflow: hidden!important;
  padding-bottom: 0px !important;
  white-space: normal !important;
  padding-right: 10px !important;
}
.ant-table-thead .ant-table-cell{
  text-align: center !important;
}
.form-check-input[type=radio] {
  border-radius: 50%;
  border: 1px solid #29529d !important;
}
.col-form-label{
  text-align: justify;
}
/* *{
  text-align: justify;
} */
.selected-row {
  background-color: #e6f7ff; /* Màu xanh nhạt */
}
.selected-row .ant-table-cell{
  background-color: #e6f7ff; /* Màu xanh nhạt */
}
.brqwiopirqpw{
  background-color: rgb(230, 230, 230);
  padding: 3px 8px;
  border-radius: 3px;
  margin-top: 5px;
}
.ant-tabs-tab-active{
  background: #bfdaff4f !important;
    font-weight: bold !important;
    /* font-size: 19px; */
    padding-right: 20px !important;
    padding-left: 20px!important;
}
.ant-tabs-tab-active div{
  /* font-size: 18px !important; */
}