.input-formx .ant-select-selector {
    height: 36px !important;
}

.ant-empty-normal {
    height: 400px;
}
.ant-btn-primary span {
    color: white;
}
.ant-table-cell-fix-left-last{
    z-index: 1 !important;
}
.ant-scroll-number-only-unit{
    color: #ffffff;
}
.ant-table-small .ant-table-cell {
    padding: 7px 6px !important;
    vertical-align: top;
}
.ant-select-dropdown{
    overflow: hidden!important;
    z-index: 3000 !important;
    max-height: 280px;
}
.ant-select-dropdown .ant-select-item-option-content {
    white-space: normal  !important;
}
.ant-select .ant-select-selector {
    /* height: 37px !important; */
    padding: 0 11px;
    border-radius: 5px !important;
    border: 1px solid #c4c4c4 !important;
}

.ant-h-unset .ant-select-selector {
    height: unset !important;
    padding: 0 11px;
    border-radius: 5px !important;
    border: 1px solid #c4c4c4 !important;
}

.ant-popconfirm {
    z-index: 10000;
}

.scroll-step {
    overflow: scroll;
    max-width: 100%;
}

.scroll-step .rs-steps {
    min-width: 780px;
}

.rs-steps-item-status-finish .rs-steps-item-title {
    color: #3498ff !important;
}

.rs-steps-item-active .rs-steps-item-title {
    font-weight: bold;
    color: #3498ff;
}

.action-column .rs-cell-content {
    display: flex !important;
    width: 90px !important;
    align-items: center !important;
    justify-content: center !important;
    height: 30px !important;
}

.action-column .link-group {
    height: 30px !important;
}

.ant-upload img {
    padding: 10px;
    width: 100%;
    height: 100%;
}

.flex-row {
    flex-direction: row;
}

.input-antd-medium .ant-select-selector {
    height: 36px !important;
}

.input-antd-medium .ant-select-selection-placeholder {
    line-height: 29px !important;

}

.input-antd-small .ant-select-selector {
    height: 30px !important;
}

.input-antd-small .ant-select-selection-placeholder {
    line-height: 25px !important;

}

.multiple-h-unset .ant-select-selector {
    height: unset !important;
    min-height: 36px;
}

.ant-table-wrapper .ant-table {
    color: black
}

.MuiTextField-root {
    width: 100%;
    margin-bottom: 16px;
}

@media (max-width: 768px) {

    .ant-picker-panels{
        display: flex !important;
        flex-direction: column !important;
    }
}